import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup} from '@mui/material';
import {NonAuthRoutes} from '../../../routes/routes';
import {Link, useLocation} from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';

import st from '../Auth.module.scss';
// import { ReactComponent as AppleIcon } from '../../../assets/images/icons/social_apple.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeIconOff } from '../../../assets/images/icons/eye-off.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/icons/mail.svg';
import {ReactComponent as InfoIcon} from "../../../assets/images/icons/info.svg";
import { observer } from 'mobx-react';
import validator from "validator";
import UIStore from "../../../store/UIStore";
import DataStore from "../../../store/DataStore";
import {postData} from "../../../api/api";
import {Or} from "../../../components/Or";
import {GoogleAuth} from "../../../components/GoogleAuth";
import {Tip} from "../../../components/Tip";


const SignUp = () => {
    const intl = useIntl();
    const routLocation = useLocation();
    const query = new URLSearchParams(routLocation.search);
    const referralCode = query.get("referralCode");
    const {localeUrl, alertTimers, setErrorAlert} = UIStore;
    const {authorizeUser} = DataStore;
    const [googleAccessToken, setGoogleAccessToken] = useState(undefined);
    const [userType, setUserType] = useState('');
    const [withMail, setWithMail] = useState(false);
    const [successSignUpIsActive, setSuccessSignUpIsActive] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const onClickMail = () => {
        setWithMail(true);
    };

    useEffect(() => {
        if (routLocation?.state) {
            setGoogleAccessToken(routLocation.state.googleAccessToken);
        }
    }, [routLocation]);

    const validate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = intl.formatMessage({id: 'required'});
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = intl.formatMessage({id: 'errors.email'});
        }

        if (!values.password) {
            errors.password = intl.formatMessage({id: 'required'});
        }

        if (values.password && values.password.length < 6) {
            errors.password = intl.formatMessage({id: 'errors.passwordMinLength'});
        }

        if (!values.confirm_password) {
            errors.confirm_password = intl.formatMessage({id: 'required'});
        }

        if (values.confirm_password && values.confirm_password !== values.password) {
            errors.confirm_password = intl.formatMessage({id: 'errors.passwordNotMatch'});
        }

        return errors;
    };

    const googleRegistration = (typeOfUser) => {
        postData('auth/google', {accessToken: googleAccessToken, userType: typeOfUser})
            .then(({data}) => {
                if (data.refresh_token && data.token) {
                    authorizeUser(data.token, data.refresh_token);
                }
            })
            .catch((errorMessage) => {
                console.log('errorMessage', errorMessage);
                setErrorAlert(errorMessage, alertTimers.long);
            });
    }

    const form = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirm_password: '',
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setErrorAlert('');
            postData('api/users', {userType: userType, email: values.email, plainPassword: values.password, referralCode: referralCode})
                .then(() => {
                    setSuccessSignUpIsActive(true);
                })
                .catch((errorMessage) => {
                    setErrorAlert(errorMessage, alertTimers.long);
                }
            );
        },
    });

    return (
        <>
            {successSignUpIsActive ?
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h3" sx={{ m: 0 }} gutterBottom>
                            {intl.formatMessage({ id: 'auth.signup.success.title' })}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography component="p" variant="body1">
                            {intl.formatMessage({ id: 'auth.signup.success.description' })}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link to={localeUrl(NonAuthRoutes.signin)} className={st.linkFull} >
                            <Button variant="contained" fullWidth color="primary" onClick={() => setSuccessSignUpIsActive(false)} autoFocus>
                                {intl.formatMessage({ id: 'auth.signup.success.button' })}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                :
                <div className={st.form_container}>
                    <Grid container spacing={1} sx={{mb: 4}}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h3" sx={{ mb: 2 }}>
                                {intl.formatMessage({id: 'auth.signup.button'})}
                                {/*{intl.formatMessage({ id: (userType ? 'joinFsexpert' : 'auth.signup.button') })}*/}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={st.user_type_title}>{intl.formatMessage({id: 'userType'})}*</Grid>

                        <Grid item xs={12} className={st.radio_tip_box}>
                            <RadioGroup
                                className={st.radio_box}
                                row={true}
                                onChange={(event) => {
                                    setUserType(event.target.value);

                                    if (googleAccessToken) {
                                        googleRegistration(event.target.value);
                                    }
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        value="client"
                                        className={classNames(st.signup_radio, (userType === 'client' ? st.checked_type : ''))}
                                        control={<Radio className={st.signup_radio_radio} />}
                                        label={intl.formatMessage({ id: 'auth.signup.client' })}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        value="company"
                                        className={classNames(st.signup_radio, (userType === 'company' ? st.checked_type : ''))}
                                        control={<Radio className={st.signup_radio_radio} />}
                                        label={intl.formatMessage({ id: 'company' })}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        value="expert"
                                        className={classNames(st.signup_radio, (userType === 'expert' ? st.checked_type : ''))}
                                        control={<Radio className={st.signup_radio_radio} />}
                                        label={intl.formatMessage({ id: 'auth.signup.expert' })}
                                    />
                                </Grid>
                            </RadioGroup>

                            <div className={st.tip}>
                                <Tip target={<InfoIcon/>} tipText={intl.formatMessage({id: 'signUpTip'})}/>
                            </div>
                        </Grid>
                    </Grid>

                    {(userType && !googleAccessToken) &&
                        <Grid container spacing={3} className={st.email_form}>
                            <Grid item xs={12}>
                                <GoogleAuth userType={userType} />
                            </Grid>

                            <Grid item xs={12}>
                                <Or />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    className={classNames(st.signup_button, st.signup_button_mail)}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={onClickMail}
                                >
                                    <MailIcon width="24" />

                                    {intl.formatMessage({ id: 'auth.signup.withMail' })}
                                </Button>
                            </Grid>
                        </Grid>
                    }

                    {withMail && (
                        <Box className={st.auth_form}>
                            <FormikProvider value={form}>
                                <Box
                                    component="form"
                                    noValidate
                                    sx={{ mt: 3 }}
                                    onSubmit={form.handleSubmit}
                                    onChange={(event) => {
                                        if (form.errors) {
                                            form.setFieldError(event.target.name, '');
                                        }
                                    }}
                                >
                                    <Grid container spacing={2.5}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="email"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "auth.signin.email"})}
                                                        variant="outlined"
                                                        error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "auth.signin.password"})}
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant="outlined"
                                                        error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" sx={{ tabIndex: "-1" }}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="confirm_password"
                                                children={(fieldProps) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        fullWidth
                                                        label={intl.formatMessage({id: "auth.signup.confirmPassword"})}
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant="outlined"
                                                        error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" sx={{ tabIndex: "-1" }}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                        className={st.button_eye}
                                                                        tabIndex={-1}
                                                                    >
                                                                        {!showPassword ? <EyeIconOff /> : <EyeIcon />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />

                                            <Grid item xs={12} mt={1}>
                                                <div className={st.terms}>
                                                    <span>{intl.formatMessage({ id: 'auth.signup.terms.read' })} </span>
                                                    <Link to={localeUrl(NonAuthRoutes.termsOfUse)} className={st.terms__link} target="_blank" rel="noopener noreferrer">
                                                        {intl.formatMessage({ id: 'contacts.form.terms.terms' })}
                                                    </Link>

                                                    <span> {intl.formatMessage({ id: 'contacts.form.terms.and' })} </span>
                                                    <Link to={localeUrl(NonAuthRoutes.privacyPolicy)} className={st.terms__link} target="_blank" rel="noopener noreferrer">
                                                        {intl.formatMessage({ id: 'contacts.form.terms.privacy' })}
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: 1 }}>
                                            <Button
                                                type="submit"
                                                disabled={!['client', 'expert', 'company'].includes(userType)}
                                                color="primary"
                                                variant="contained"
                                                sx={{ width: '100%', mb: 4 }}
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'auth.signup.button' }) }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                            </FormikProvider>
                        </Box>
                    )}

                    <div className={st.already_signin}>
                        <span className="color-dark">{intl.formatMessage({id: 'alreadyAmember'})} </span>

                        <Link to={localeUrl(NonAuthRoutes.signin)} className="color-accent">
                            {intl.formatMessage({ id: 'app.header.signIn' })}
                        </Link>
                    </div>
                </div>
            }
        </>
    );
};

export default observer(SignUp);
