import React from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react";
import stTags from "../../components/WithStyledTags/withStyledTags.module.scss"
import st from '../PrivacyPolicy/privacyPolicy.module.scss';
import classNames from "classnames";


const TermsOfUse = () => {
    const intl = useIntl();

    function formatHumanDate(date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    return (
        <>
            <section>

            </section>

            <section className={classNames(stTags.with_styled_tags, st.main)}>
                <div className={st.main_container}>
                    <div className={st.hero_container}>
                        <h1 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "termsOfUse.title" }) }}></h1>

                        <div className={st.date}>{formatHumanDate(new Date())}</div>
                    </div>
                    <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "termsOfUse.content.title" }) }}></h2>
                    <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "termsOfUse.content.description.1" }) }}></div>
                    {/*<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "termsOfUse.content.description.2" }) }}></div>*/}
                </div>
            </section>
        </>
    );
};

export default observer(TermsOfUse);
