import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import stTags from '../../components/WithStyledTags/withStyledTags.module.scss';
import st from './privacyPolicy.module.scss';
import classNames from 'classnames';

const PrivacyPolicy = () => {
    const intl = useIntl();

    function formatHumanDate(date) {
        const day = intl.formatDate(new Date(date), { day: 'numeric' });
        const month = intl.formatDate(new Date(date), { month: 'long' });
        const year = intl.formatDate(new Date(date), { year: 'numeric' });

        return `${month} ${day}, ${year}`;
    }

    return (
        <section className={classNames(stTags.with_styled_tags, st.main)}>
            <div className={st.hero_container}>
                <h1>{intl.formatMessage({ id: 'privacyPolicy.title' })}</h1>

                <div className={st.date}>{formatHumanDate(new Date())}</div>
            </div>

            <div className={st.main_container}>
                <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'privacyPolicy.content.title' }) }}></h2>
                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "privacyPolicy.content.description.1" }) }}></div>
            </div>
        </section>
    );
};

export default observer(PrivacyPolicy);
